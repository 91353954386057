import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Layout, Menu, Typography } from 'antd';
import {
  WalletOutlined,
  ApiOutlined,
  AuditOutlined,
  TeamOutlined,
  GlobalOutlined,
  BranchesOutlined,
  FileExcelOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import BillingModal from './BillingModal';
import RoleSelectionModal from './RoleSelectionModal';

const { Sider } = Layout;
const { Text } = Typography;

const VERSION_NUMBER = 'v1.1.3'; // TODO: UPDATE WITH EACH MERGE

const Sidebar = () => {
  const location = useLocation();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { user, members } = useSelector(state => state.user);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isBillingModalVisible, setIsBillingModalVisible] = useState(false);

  const getCurrentActiveItem = () => {
    const pathNameArray = location.pathname.split('/');
    return pathNameArray.length < 5
      ? `/${pathNameArray.slice(2, pathNameArray.length).join('/')}`
      : `/${pathNameArray.slice(2, 4).join('/')}`;
  };

  return (
    <Sider
      width={222}
      theme="light"
      collapsible
      collapsed={isCollapsed}
      trigger={
        <>
          <p>
            {!isCollapsed ? (
              <LeftOutlined onClick={() => setIsCollapsed(true)} />
            ) : (
              <RightOutlined onClick={() => setIsCollapsed(false)} />
            )}
          </p>
          <Text disabled>{VERSION_NUMBER}</Text>
        </>
      }
    >
      <div className={`logo ${isCollapsed && 'logo--collapsed'}`} />
      <Menu
        className="space-vertical-md"
        mode="vertical"
        theme="light"
        selectedKeys={getCurrentActiveItem()}
      >
        <Menu.ItemGroup
          title={!isCollapsed && t('nav.sidebar.kyc').toUpperCase()}
        >
          <Menu.Item key={`/kyc/company`}>
            <Link to={`/${language}/kyc/company`}>
              <WalletOutlined />
              <span>{t('nav.sidebar.company')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={`/kyc/partners`}>
            <Link to={`/${language}/kyc/partners`}>
              <ApiOutlined />
              <span>{t('nav.sidebar.partner')}</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={`/kyc/shared`}>
            <Link to={`/${language}/kyc/shared`}>
              <BranchesOutlined />
              <span>{t('nav.sidebar.shared')}</span>
            </Link>
          </Menu.Item>
        </Menu.ItemGroup>
        {user.Role === 'KYCAdmin' ? (
          <Menu.ItemGroup
            title={!isCollapsed && t('nav.sidebar.admin').toUpperCase()}
          >
            <Menu.Item key={`/admin/service`}>
              <Link to={`/${language}/admin/service`}>
                <AuditOutlined />
                <span>{t('nav.sidebar.services')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`/admin/users`}>
              <Link to={`/${language}/admin/users`}>
                <TeamOutlined />
                <span>{t('nav.sidebar.users')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key={`/admin/translation`}>
              <Link to={`/${language}/admin/translation`}>
                <GlobalOutlined />
                <span>{t('nav.sidebar.translations')}</span>
              </Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => setIsBillingModalVisible(true)}
              key={`/admin/billing`}
            >
              <FileExcelOutlined />
              <span>{t('nav.sidebar.billing')}</span>
            </Menu.Item>
          </Menu.ItemGroup>
        ) : null}
      </Menu>
      <BillingModal
        isVisible={isBillingModalVisible}
        setIsVisible={setIsBillingModalVisible}
      />
      {members.length > 1 ? <RoleSelectionModal /> : null}
    </Sider>
  );
};

export default Sidebar;
